<template>
	<div class="EMS">
		<!-- 背景 -->
		<div class="EMS_bg">
			<the-nav></the-nav>
			<div class="EMS_bg_bottom">
				<img
					class="bg_header"
					src="../../assets/imgs/img-product-a.png"
					alt="轻EMS"
				/>
				<img
					class="bg_logo"
					src="../../assets/imgs/img-logo-a.png"
					alt="轻EMS"
				/>
				<h1>
					新一代 <br />
					云边一体智能EMS
				</h1>
				<h6>易用安全，高效智能的能量管理系统</h6>
				<div class="yuyue_btn yuyue_btn2" @click="booking">
					预约体验
				</div>
			</div>
		</div>
		<!-- 1 -->
		<div class="EMS_box bg_white">
			<div class="EMS_box_con1">
				<div class="top">
					<div class="common_title">
						<p class="cn_title">新一代云边一体设计</p>
					</div>
					<div class="top_description">
						摒弃传统本地化软件设计思路，云边一体无缝通信设计
						支持单站运行，云端监管备份，多站集控协同。
						云端金融级大数据平台架构，海量数据处理，性能更强，安全性更高。
					</div>
				</div>
				<img
					class="bottom"
					src="../../assets/imgs/img-ems-picture-a.png"
					alt=""
				/>
			</div>
		</div>
		<!-- 2 -->
		<div class="EMS_box bg_blue">
			<div class="EMS_box_con1">
				<div class="top">
					<div class="common_title common_title2">
						<span class="cn_title" style="color: #fff"
							>远程遥控/遥调</span
						>
					</div>
					<div
						class="top_description"
						style="color: rgba(255, 255, 255, 0.65)"
					>
						可在云端进行PCS/空调策略调整，实现少人/无人值守。紧急状况下遥控系统启停，无需奔赴现场，及时保障电站安全。
					</div>
				</div>
				<img
					class="bottom"
					src="../../assets/imgs/img-ems-picture-b.png"
					alt=""
				/>
			</div>
		</div>
		<!-- 3 -->
		<div class="EMS_box bg_gray">
			<div class="EMS_box_con3">
				<div class="common_title">
					<span class="cn_title">多种策略支持</span>
					<span class="en_title">Multiple policy support</span>
				</div>
				<div class="swiper strategySwiper">
					<div class="swiper-wrapper">
						<div
							class="swiper-slide swiper-strategy"
							v-for="(item, index) in strategyList"
							:key="'strategy' + index"
						>
							<h3 class="title">{{ item.name }}</h3>
							<div class="hr"></div>
							<img :src="item.url" alt="item.name" />
						</div>
					</div>
					<!-- 如果需要分页器 -->
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
		<!-- 4 -->
		<div class="EMS_box bg_white">
			<div class="EMS_box_con4">
				<div class="common_title">
					<span class="cn_title">产品特点</span>
					<span class="en_title">Product features</span>
				</div>
				<div class="swiper proSwiper">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div
								class="proSwiper-item"
								v-for="(item, index) in productList.slice(0, 3)"
								:key="'pro' + index"
							>
								<svg class="left" aria-hidden="true">
									<use :xlink:href="`#${item.svg}`"></use>
								</svg>
								<div class="right">
									<div class="right_title">
										{{ item.name }}
									</div>
									<div class="right_desc">
										{{ item.description }}
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-slide">
							<div
								class="proSwiper-item"
								v-for="(item, index) in productList.slice(3)"
								:key="'pro' + index"
							>
								<svg class="left" aria-hidden="true">
									<use :xlink:href="`#${item.svg}`"></use>
								</svg>
								<div class="right">
									<div class="right_title">
										{{ item.name }}
									</div>
									<div class="right_desc">
										{{ item.description }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 如果需要分页器 -->
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
		<!-- 5 -->
		<div class="EMS_box bg_gray">
			<div class="EMS_box_con5">
				<div class="common_title">
					<span class="cn_title">产品价值</span>
					<span class="en_title">Product value</span>
				</div>
				<div
					class="box bg_white"
					v-for="(item, index) in valueList"
					:key="'value' + index"
				>
					<div class="box_title">{{ item.name }}</div>
					<div class="box_desc">{{ item.description }}</div>
					<img class="box_img" :src="item.url" :alt="item.name" />
				</div>
			</div>
		</div>
		<!-- footer -->
		<the-footer></the-footer>
	</div>
</template>

<script>
	// import Swiper from "swiper";
	import Swiper from "swiper/js/swiper.min.js";
	import "swiper/css/swiper.min.css";
	export default {
		name: "EMS",
		data() {
			return {
				// 策略
				strategyList: [
					{
						name: "削峰填谷",
						url: require("../../assets/imgs/img-ems-data-a.png")
					},
					{
						name: "需量控制",
						url: require("../../assets/imgs/img-ems-data-b.png")
					},
					{
						name: "离网备电",
						url: require("../../assets/imgs/img-ems-data-c.png")
					},
					{
						name: "光储微网",
						url: require("../../assets/imgs/img-ems-data-d.png")
					}
				],
				// 产品特点
				productList: [
					{
						name: "智能策略",
						description:
							"基于人工智能的策略运行，最优化策略选择和切换，保障收益最大化。",
						svg: "a-icon-ems-a1x"
					},
					{
						name: "主动安全",
						description:
							"无缝衔接储电医生，常见电池异常定时诊断，为电站保安全延寿命。",
						svg: "a-icon-ems-b1x"
					},
					{
						name: "全量监控",
						description:
							"覆盖传统EMS盲区，消防，视频监控等多类设备全量接入。",
						svg: "a-icon-ems-c1x"
					},
					{
						name: "实施快捷",
						description:
							"广泛兼容主流设备厂商，配合智能调试验证技术，实施快人一步。",
						svg: "a-icon-ems-d1x"
					},
					{
						name: "加密安全",
						description:
							"采用SSL、VPN等安全技术，保障网络传输数据加密安全。",
						svg: "a-icon-guard-line-c1x"
					},
					{
						name: "易用好用",
						description:
							"互联网产品设计，简明易用，更支持手机APP，随时随地掌控电站。",
						svg: "a-icon-ems-f1x"
					}
				],
				// 价值
				valueList: [
					{
						name: "储能EPC",
						description:
							"能量管理和系统信息化全部交给轻EMS，快速交付项目，省时省力更省成本，还可增加产品竞争力。",
						url: require("../../assets/mobile/img-m-ems-value-a.png")
					},
					{
						name: "PCS厂商",
						description:
							"与轻EMS整合配套，智能融合，增加产品竞争力，拓展销售渠道。",
						url: require("../../assets/mobile/img-m-ems-value-b.png")
					}
				]
			};
		},
		mounted() {
			this.initSwiper();
		},
		methods: {
			booking() {
				this.$store.commit("setState", { visible: true });
			},
			initSwiper() {
				this.$nextTick(() => {
					new Swiper(".strategySwiper", {
						direction: "horizontal", // 垂直切换选项
						loop: true,
						pagination: {
							el: ".swiper-pagination",
							bulletClass: "my-bullet",
							bulletActiveClass: "my-bullet-active"
						}
					});
					new Swiper(".proSwiper", {
						direction: "horizontal", // 垂直切换选项
						loop: true,
						pagination: {
							el: ".swiper-pagination",
							bulletClass: "my-bullet",
							bulletActiveClass: "my-bullet-active"
						}
					});
				});
			}
		}
	};
</script>

<style lang="scss">
	.EMS {
		&_box {
			padding: px2rem(40) px2rem(24);
			.common_title {
				position: relative;
				display: flex;
				flex-direction: column;
				padding-bottom: px2rem(24);
				.cn_title {
					font-size: px2rem(24);
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.85);
				}
				.en_title {
					font-size: px2rem(14);
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: rgba(0, 0, 0, 0.25);
					text-transform: uppercase;
					margin-top: px2rem(4);
				}
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					content: "";
					display: block;
					width: 100%;
					height: px2rem(2);
					background-color: #e6e6e6;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 3;
					content: "";
					display: block;
					width: px2rem(77);
					height: px2rem(2);
					background-color: #00c699;
				}
			}
			.swiper {
				position: relative;
				width: 100%;
				height: px2rem(371);
				overflow: hidden;
				&-wrapper {
					position: relative;
					display: flex;
					width: 100%;
					height: px2rem(341);
				}
				&-slide {
					flex-shrink: 0;
					width: 100%;
					height: 100%;
					background-color: #fff;
				}
				&-pagination {
					bottom: 0;
					background-color: transparent;
					.my-bullet {
						display: inline-block;
						width: px2rem(6);
						height: px2rem(6);
						background-color: rgba(0, 0, 0, 0.1);
						border-radius: px2rem(4);
						margin-right: px2rem(8);
						&:last-child {
							margin-right: 0;
						}
					}
					.my-bullet-active {
						width: px2rem(12);
						height: px2rem(6);
						background-color: #00c699;
						border-radius: px2rem(4);
					}
				}
			}
			&_con1 {
				display: flex;
				flex-direction: column;
				.top {
					font-size: px2rem(24);
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: left;
					color: #0c0e25;
					&_description {
						font-size: px2rem(14);
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
						margin: px2rem(24) 0;
					}
				}
				.bottom {
					height: px2rem(435);
				}
			}
			&_con3 {
				.strategySwiper {
					margin-top: px2rem(40);
					.swiper-strategy {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.title {
							position: relative;
							font-size: px2rem(16);
							font-family: PingFangSC, PingFangSC-Medium;
							font-weight: 500;
							text-align: center;
							color: rgba(0, 0, 0, 0.85);
							padding-bottom: px2rem(16);
							&::after {
								position: absolute;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								display: block;
								content: "";
								width: px2rem(16);
								height: px2rem(2);
								background-color: #00c699;
							}
						}
						.hr {
							width: px2rem(263);
							@include thinBorder(bottom, #e6e6e6);
							margin: px2rem(24) auto;
						}
						img {
							width: px2rem(311);
							height: px2rem(186);
							object-fit: contain;
						}
					}
				}
			}
			&_con4 {
				.proSwiper {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: auto;
					margin-top: px2rem(24);
					padding-bottom: px2rem(24);
					.swiper-wrapper {
						height: auto;
					}
					&-item {
						display: flex;
						padding: px2rem(24) px2rem(16);
						border-radius: px2rem(4);
						background-color: #f4f5f7;
						margin-bottom: px2rem(14);
						.left {
							width: px2rem(40);
							height: px2rem(40);
							margin-right: px2rem(16);
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
						.right {
							flex: 1;
							display: flex;
							flex-direction: column;
							&_title {
								padding-bottom: px2rem(8);
								@include thinBorder(bottom, #e6e6e6);
								font-size: px2rem(16);
								font-family: PingFangSC, PingFangSC-Medium;
								font-weight: 600;
								text-align: left;
								color: rgba(0, 0, 0, 0.85);
							}
							&_desc {
								margin-top: px2rem(8);
								font-size: px2rem(14);
								font-family: PingFangSC, PingFangSC-Regular;
								font-weight: 400;
								text-align: left;
								color: rgba(0, 0, 0, 0.45);
							}
						}
					}
				}
			}
			&_con5 {
				.box {
					&:nth-child(2) {
						margin-top: px2rem(40);
					}
					position: relative;
					height: px2rem(296);
					padding: px2rem(32) px2rem(24);
					margin-bottom: px2rem(54);
					&_title {
						padding-bottom: px2rem(16);
						font-size: px2rem(16);
						font-family: PingFangSC, PingFangSC-Semibold;
						font-weight: 500;
						text-align: left;
						color: rgba(0, 0, 0, 0.85);
						@include thinBorder(bottom, rgba(0, 0, 0, 0.08));
					}
					&_desc {
						margin-top: px2rem(16);
						height: px2rem(66);
						font-size: px2rem(14);
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.45);
					}
					&_img {
						position: absolute;
						left: px2rem(24);
						bottom: px2rem(-21);
						z-index: 100%;
						width: px2rem(279);
						height: px2rem(146);
						object-fit: contain;
					}
				}
			}
		}
	}
</style>
